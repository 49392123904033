import { render, staticRenderFns } from "./hosting.minecraft.budget.vue?vue&type=template&id=7b9f9ec2&scoped=true"
var script = {}
import style0 from "./hosting.minecraft.budget.vue?vue&type=style&index=0&id=7b9f9ec2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b9f9ec2",
  null
  
)

export default component.exports